import { Grommet, Box, Header, Button, Heading, Main, Card, CardHeader, CardBody, CardFooter, Text, Footer, Layer, Select, Form, FormField, TextArea, TextInput, Image } from 'grommet';
import { Add, LinkDown, LinkUp } from 'grommet-icons';
import { useEffect, useState } from 'react';
import './lmao.css'

const theme = {
  "name": "my theme",
  "rounding": 4,
  "spacing": 24,
  "defaultMode": "light",
  "global": {
    "colors": {
      "brand": {
        "dark": "#cc7933",
        "light": "#ffc44e"
      },
      "background": {
        "dark": "#111111",
        "light": "#FFFFFF"
      },
      "background-back": {
        "dark": "#111111",
        "light": "#EEEEEE"
      },
      "background-front": {
        "dark": "#222222",
        "light": "#FFFFFF"
      },
      "background-contrast": {
        "dark": "#FFFFFF11",
        "light": "#11111111"
      },
      "text": {
        "dark": "#EEEEEE",
        "light": "#333333"
      },
      "text-strong": {
        "dark": "#FFFFFF",
        "light": "#000000"
      },
      "text-weak": {
        "dark": "#CCCCCC",
        "light": "#444444"
      },
      "text-xweak": {
        "dark": "#999999",
        "light": "#666666"
      },
      "border": {
        "dark": "#444444",
        "light": "#CCCCCC"
      },
      "control": "brand",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#FF4040",
      "status-warning": "#FFAA15",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning"
    },
    "font": {
      "family": "Helvetica"
    },
    "active": {
      "background": "active-background",
      "color": "active-text"
    },
    "hover": {
      "background": "active-background",
      "color": "active-text"
    },
    "selected": {
      "background": "selected-background",
      "color": "selected-text"
    }
  },
  "chart": {},
  "diagram": {
    "line": {}
  },
  "meter": {},
  "layer": {
    "background": {
      "dark": "#111111",
      "light": "#FFFFFF"
    }
  }
};

function App() {
  const [posts, setPosts] = useState([])

  const [postDialog, setPostDialog] = useState(false)
  const [formValue, setFormValue] = useState({})

  const refresh = () => fetch("https://orangedit.leoorshansky.workers.dev/posts").then(x => x.json()).then(x => setPosts(x))
  useEffect(refresh, [])

  const vote = async (id, direction) => {
    await fetch(`https://orangedit.leoorshansky.workers.dev/vote/${direction}`, {
      method: "POST",
      body: JSON.stringify({ postId: id }),
      headers: {
        "Content-Type": "application/json"
      },
      mode: 'no-cors'
    })
    const change = direction === "up" ? 1 : -1
    let copyPosts = posts
    let count = 0
    for (let post of copyPosts) {
      if (post.id === id) {
        copyPosts[count].score += change
        setPosts([...copyPosts])
        break
      }
      count++
    }
  }

  const post = value => {
    if (!value["type"] || !value["title"] || !value["body"] || !value["user"])
      return
    fetch(`https://orangedit.leoorshansky.workers.dev/posts`, {
      method: "POST",
      body: JSON.stringify({
        type: value["type"].toLowerCase(),
        title: value["title"],
        content: value["body"],
        username: value["user"]
      }),
      headers: {
        "Content-Type": "application/json"
      },
      mode: 'no-cors'
    }).then(res => {
      if (res.status !== 200) {
        setPostDialog(false)
        refresh()
      }
    })
  }

  return (
    <Grommet theme={theme} style={{ height: "100%" }}>
      <Header background="brand" justify="stretch" round="medium" width="max-content" style={{ "position": "fixed" }} margin="20px">
        <Button primary size="small" label={<Heading level="4">Create a Post</Heading>} icon={<Add size="medium" />} hoverIndicator onClick={() => setPostDialog(true)} />
        {postDialog && (
          <Layer onEsc={() => setPostDialog(false)} onClickOutside={() => setPostDialog(false)}>
            <Card width="800px" pad="small">
              <Form value={formValue} onChange={nextValue => setFormValue(nextValue)} onSubmit={event => post(event.value)}>
                <Heading margin="xsmall" textAlign="center" level="3">Post Details</Heading>
                <CardBody border="top">
                  <FormField label="Username:"><TextInput name="user" placeholder="coolguy123"/></FormField>
                  <FormField label="Post Title:"><TextInput name="title" placeholder="An interesting title"/></FormField>
                  <Text margin="xsmall">Type of post:</Text>
                  <Select name="type" options={['Link', 'Text', 'Image']}/>
                  { formValue["type"] === "Text"
                    ? <FormField label="Post Body:"><TextArea name="body" placeholder="Write something cool!"/></FormField>
                    : <FormField label="Link:"><TextInput name="body"/></FormField>
                  }
                </CardBody>
                <CardFooter>
                  <Button type="submit" primary label="Submit" />
                </CardFooter>
              </Form>
            </Card>
          </Layer>
        )}
      </Header>
      <Main background={{ color: "brand", opacity: "medium" }} align="center">
        <Heading margin="small" color="neutral-2">Orangedit</Heading>
        <Box direction="column" width="70%" background={{ color: "white", opacity: "100%" }} style={{"overflow": "scroll"}}>
          {posts.map(post =>
            <Card margin="medium" key={post.id} style={{"flex": "none"}}>
              <CardHeader height="min-content" justify="evenly"><Heading level="4" margin="10px">{post.title}</Heading></CardHeader>
              <Text size="small" margin={{left: "small", bottom: "xsmall"}}>by: {post.username}</Text>
              <CardBody border="top" pad="small" style={{ whiteSpace: "pre-wrap" }}>
                {(post.type === "text"
                  ? post.content
                  : post.type === "image" ? <Image src={post.content}/>
                  : <a href={post.content}>{post.content}</a>
                )}
              </CardBody>
              <CardFooter pad="small">
                <Footer>
                  <Button size="small" icon={<LinkUp size="small" />} label="Upvote" onClick={() => vote(post.id, "up")} />
                  <Button size="small" icon={<LinkDown size="small" />} label="Downvote" onClick={() => vote(post.id, "down")} />
                </Footer>
                <Box pad="small" border={{
                  "color": "status-critical",
                  "size": "small",
                }} round="medium">
                  <Text tag="b">Score: {post.score}</Text>
                </Box>
              </CardFooter>
            </Card>
          )}
          {posts.length > 0 ? null : <Card margin="medium" align="center"><Heading>Be the first to post!</Heading></Card>}
        </Box>
      </Main>
    </Grommet>
  );
}

export default App;
